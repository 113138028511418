@import "mixins/reset";
@import "mixins/media";
@import "mixins/pnop";

// sticky footer
html {
    min-height: 100%;
    display: flex; }
body {
    min-height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    #content {
        flex: 1 1 auto; } }

// overall settings
body {
    font-family: $standard-font-stack; }

// generics
.wrapper {
    width: 57rem;
    max-width: calc(100% - 2em);
    margin: 0 auto; }

pre, tt, code {
    font-size: 90%;
    font-family: $mono-font-stack; }
pre {
    padding: 1rem;
    border-radius: 0.5rem;
    background: #333;
    page-break-inside: avoid; }

a {
    text-decoration: none;
    color: #adf; }

ul li {
    list-style: square;
    list-style-position: inside;
    + li {
        padding-top: 0.75em; } }

.displaynone {
    display: none; }

#darkmodebtn {
    display: none; } // for now
